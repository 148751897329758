import React from 'react'

import { Layout } from '@globals'
import { Link } from '@elements'

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="container relative z-30 max-w-screen-xl">
          <div className="max-w-lg leading-none md:max-w-xl">
            <h1 className="font-serif text-5xl font-medium text-brand-gray-500 md:text-6xl">
              Oops! Page not found.
            </h1>
            <div className="mt-16">
              <Link to="/" kind="button" className="text-brand-gray-500">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
